<template>
  <div class="pt-36 bg-gray-100 pb-20">
    <div
      class="doc relative mx-auto rounded-lg"
      style="max-width: 800px"
      id="politicas-privacidade"
    >
      <section class="z-50 container mx-auto px-4 pdf-item py-12">
        <div class="mx-auto prose text-justify">
          <h1
            class="text-left uppercase mb-4 text-gray-900 font-bold"
            style="font-size: 22px"
          >
            Política de Privacidade
          </h1>

          <p class="pdf-item">
            <b>CONTROLADORA DOS DADOS:</b> {{ nm_rz_social }} (“{{
              nm_fantasia
            }}”), inscrita no CNPJ sob o n.º {{ formatCNPJ(cd_cnpj) }},
            endereço: {{ nm_endereco_logradouro }}, n° {{ cd_endereco_numero }},
            {{ nm_endereco_complemento }}, {{ nm_endereco_bairro }},
            {{ nm_endereco_cidade }}, CEP {{ formatCEP(cd_endereco_cep) }}.
          </p>

          <p class="pdf-item">Olá, bem-vindo(a)!</p>

          <p class="pdf-item">
            Nós da {{ nm_fantasia }} valorizamos a privacidade e queremos
            contribuir para a proteção de dados pessoais. Por essa razão, a
            seguir apresentamos nossa Política de Privacidade, para que você
            saiba como fazemos uso de seus dados pessoais.
          </p>

          <h3>O que faz a {{ nm_fantasia }}?</h3>
          <p class="pdf-item">
            Atuamos principalmente na seguinte frente de negócio:
          </p>

          <p class="pdf-item">
            <b>{{ nm_atividade_principal }}</b>
          </p>

          <p class="pdf-item">{{ ds_atividade_principal }}</p>

          <h3>
            Como funciona o tratamento de dados por parte da {{ nm_fantasia }}?
          </h3>

          <p class="pdf-item">
            Como toda a empresa, para realizar nossas atividades, precisamos
            coletar e tratar algumas informações que são consideradas pela
            legislação como dados pessoais. Para deixá-lo informado acerca do
            modo como fazemos o tratamento desses dados, preparamos o quadro
            resumo a seguir, com as principais perguntas que você pode ter.
          </p>

          <p class="pdf-item">
            Caso queira obter maiores informações sobre alguns dos tópicos,
            clique em Leia Mais na seção correspondente abaixo.
          </p>

          <template v-if="is_coletado_dados_pessoais">
            <h3>Quais Dados Pessoais Coletamos?</h3>
            <p class="pdf-item">
              Em síntese, coletamos os seguintes dados pessoais, mas não
              restritos a:
            </p>

            <ul>
              <li v-if="is_coletado_nome">Nome;</li>
              <li v-if="is_coletado_dt_nascimento">Data de nascimento;</li>
              <li v-if="is_coletado_endereco_comercial">Endereço comercial;</li>
              <li v-if="is_coletado_email">E-mail</li>
              <li v-if="is_coletado_telefone">Número de telefone/celular;</li>
              <li v-if="is_coletado_info_pagamento">
                Informações de pagamento;
              </li>
              <li v-if="is_coletado_midias_sociais">
                Perfil de mídias sociais;
              </li>
              <li v-if="is_coletado_endereco_residencial">
                Endereço residencial.
              </li>
            </ul>

            <p class="pdf-item">
              Para além dessas situações, poderemos utilizar seus dados pessoais
              de forma anonimizada (ou seja, de maneira que não leve à
              identificação de ninguém), com a finalidade de produzir relatórios
              analíticos de inteligência de negócio, a fim de melhorar nossos
              serviços.
            </p>

            <h3>Para que utilizamos os Dados Pessoais?</h3>

            <p class="pdf-item">
              Em síntese, utilizamos os dados pessoais coletados para
              finalidades ligadas à prestação de nossos serviços e às empresas
              que nos contratam. Nesse contexto, os dados pessoais nos são úteis
              para:
            </p>

            <ul>
              <li v-if="is_utilizado_ux">
                Customizar a experiência do usuário;
              </li>
              <li v-if="is_utilizado_manu_adm">
                Manutenção e administração de registros;
              </li>
              <li v-if="is_utilizado_pubi_mark">Publicidade e marketing;</li>
              <li v-if="is_utilizado_acesso">Garantir o acesso ao site;</li>
              <li v-if="is_utilizado_analytics">
                Analytics e pesquisa de mercado;
              </li>
              <li v-if="is_utilizado_compliance">
                Compliance &amp; solução de conflitos;
              </li>
              <li v-if="is_utilizado_contatar">Contatar os usuários;</li>
              <li v-if="is_utilizado_concursos">Concursos e promoções;</li>
              <li v-if="is_utilizado_recrutamento">
                Recrutamento e seleção de pessoas.
              </li>
            </ul>
          </template>

          <template v-if="is_compartilhado_dados">
            <h3>Com quem compartilhamos os Dados Pessoais?</h3>

            <p class="pdf-item">
              Os dados pessoais podem ser compartilhados com alguns
              destinatários, quais sejam:
            </p>

            <p class="pdf-item" v-if="is_compartilhado_plat_dados">
              <b>Plataformas de dados:</b> alguns de nossos clientes podem
              utilizar plataformas de dados, para melhorar a eficiência e
              agilidade de seus serviços e comunicação com outros players de
              mercado, incluindo a {{ nm_rz_social }}. Nessas comunicações,
              novamente temos de compartilhar os identificadores que possuímos
              com as referidas plataformas.
            </p>

            <p
              class="pdf-item"
              v-if="is_compartilhado_conferencia_indentificadores"
            >
              <b>Parceiros de conferência de identificadores:</b> para melhorar
              a eficiência da associação dos nossos identificadores com outros,
              podemos ter de compartilhar com nossos parceiros os
              identificadores que designamos aos navegadores por meio de
              cookies, como por exemplo parceiros relacionadas a intermediação
              de pagamentos.
            </p>

            <p class="pdf-item" v-if="is_compartilhado_combate_fraudes">
              <b>Parceiros de combate a fraudes:</b> para permitir que os
              parceiros possam efetivamente detectar fraudes, temos de
              compartilhar alguns dados pessoais por eles indicados.
            </p>

            <p class="pdf-item" v-if="is_compartilhado_parceiro">
              <b>Parceiros de nossos clientes:</b> alguns clientes podem pedir
              que compartilhemos dados com seus parceiros, por meio métricas que
              exibimos a vocês por meio de nossos clientes, para propósitos
              compatíveis com os originalmente aplicáveis (por exemplo, para
              medir a eficiência de determinado produto listado).
            </p>

            <p class="pdf-item" v-if="is_compartilhado_autoridade_oficiais">
              <b>Autoridades públicas ou órgãos oficiais:</b> para cumprimento
              de obrigações legais a que estamos submetidos, podemos ter de vir
              a compartilhar dados com autoridades públicas ou órgãos oficiais,
              mediante solicitação ou previsão legal expressa.
            </p>
          </template>

          <template v-if="is_utilizado_cookie">
            <h3>Política de Cookie</h3>

            <h4>O que são cookies?</h4>

            <p>
              Os cookies são arquivos digitais com pequenos fragmentos de dados
              (e geralmente com um identificador único) que são salvos e
              armazenados no dispositivo do usuário de uma plataforma digital.
              Eles podem ser classificados como cookies temporários (sendo
              automaticamente apagados quando o navegador ou aplicativo é
              encerrado) ou cookies persistentes (que permanecem armazenados no
              dispositivo até uma data de expiração definida), bem como cookies
              originais (definidos pelo responsável que opera a plataforma) ou
              cookies terceiros (definidos por aplicações sob responsabilidade
              de terceiros) Os cookies podem ser categorizados conforme o seu
              propósito. Existem cookies que são estritamente necessários para o
              correto funcionamento das plataformas, enquanto outros coletam
              dados estatísticos com a finalidade de analisar a utilização da
              plataforma e seu respectivo desempenho. Também há cookies que são
              utilizados para assegurar a disponibilização de funcionalidades
              adicionais das plataformas ou para guardar as preferências
              definidas pelo usuário no uso da plataforma, sempre que utilizar o
              mesmo dispositivo. Já outros cookies podem servir, ainda, para
              medir o sucesso de aplicações e a eficácia da publicidade de
              terceiros.
            </p>

            <h4>Por que utilizamos Cookies?</h4>

            <p>
              Atualmente, a utilização de cookies comum em qualquer plataforma
              digital. Seu uso não prejudica de forma alguma os dispositivos
              (computadores, tablets, celulares, etc.) dos usuários em que são
              armazenados. Eles aprimoram a experiência do usuário, tanto em
              termos de performance, como em termos de usabilidade, uma vez que
              os conteúdos disponibilizados serão direcionados às necessidades e
              expectativas do usuário.
            </p>

            <p>
              Os cookies permitem que uma plataforma digital memorize
              informações sobre a visita do usuário, o seu idioma preferido, a
              sua localização, a recorrência das suas sessões e outras variáveis
              que nós consideramos relevantes para tornar a sua experiência
              muito mais eficiente.
            </p>

            <p>
              Os cookies também poderão ser utilizados para compilar
              estatísticas anônimas e agregadas que permite entender como os
              usuários utilizam as nossa plataforma, bem como para aprimorar
              suas estruturas e conteúdo. É importante dizer que não podemos
              identificá-lo pessoalmente por meio desses dados.
            </p>

            <h4>Quais tipos de cookies são utilizados?</h4>

            <template v-if="is_utilizado_cookie_necessarios">
              <p><b>Cookies essenciais</b></p>
              <p>
                Esses cookies são essenciais para que as nossas plataformas
                digitais funcionem corretamente. Eles permitem que o usuário
                navegue em nossos sites e use todas suas funcionalidades. Os
                exemplos incluem lembrar ações anteriores (por exemplo, textos
                inseridos) quando você volta a navegar em uma página em uma
                mesma sessão.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_desempenho">
              <p><b>Cookies de Pesquisa, Análise e Desempenho</b></p>
              <p>
                A finalidade deste tipo de cookie é ajudar a entender o
                desempenho das nossas plataformas digitais, medir sua audiência,
                verificar os hábitos de navegação dos usuários, bem como a forma
                pelo qual chegou à página deste (por exemplo, através de links
                de outros sites, buscadores ou diretamente pelo endereço).
              </p>
            </template>

            <template v-if="is_utilizado_cookie_marketing">
              <p><b>Cookies de publicidade</b></p>
              <p>
                Esses cookies são usados para tornar as mensagens publicitárias
                mais relevantes para você.Eles executam funções como impedir que
                o mesmo anúncio reapareça continuamente, garantindo que os
                anúncios sejam exibidos de maneira adequada para os anunciantes
                e, em alguns casos, selecionando anúncios que são baseados em
                seus interesses.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_rede_sociais">
              <p><b>Cookies de redes sociais</b></p>
              <p>
                Esses cookies são usados para permitir que você compartilhe
                páginas e conteúdo que considere interessantes em nossos sites
                por meio de redes sociais de terceiros e outros sites.Esses
                cookies também podem ser usados para fins publicitários.
              </p>
            </template>

            <template v-if="is_utilizado_cookie_auth">
              <p><b>Cookies de autenticação</b></p>
              <p>
                Servem para reconhecer um determinado usuário, possibilitando o
                acesso e utilização da Plataforma com conteúdo e/ou serviços
                restritos e proporcionando experiências de navegação mais
                personalizadas.
              </p>
            </template>
          </template>

          <template v-if="is_protegido_dados">
            <h3>Como protegemos os Dados Pessoais?</h3>

            <p class="pdf-item">
              Na {{ nm_rz_social }} implementamos todas as medidas de segurança
              que estão ao nosso alcance para proteger os seus dados pessoais de
              acessos não autorizados e de situações acidentais ou ilícitas de
              destruição, perda, alteração, comunicação ou difusão.
            </p>

            <p class="pdf-item">Como por exemplo:</p>

            <p class="pdf-item" v-if="is_protegido_politica_interna">
              Temos uma política interna de proteção de dados, que se aplica a
              todos aqueles que tratam dados pessoais em nosso nome.
            </p>

            <p class="pdf-item" v-if="is_protegido_plano_resposta">
              Contamos com um robusto plano de resposta a incidentes em caso de
              ocorrência de alguma situação indesejada relacionada aos dados que
              tratamos.
            </p>

            <p class="pdf-item" v-if="is_protegido_ssl">
              Possuímos certificação SSL (Security Socket Layer) que garante a
              proteção por criptografia dos dados e chave de segurança
              utilizando o protocolo SSL de 128 bits.
            </p>

            <p class="pdf-item" v-if="is_protegido_treinamento">
              Por fim, nos preocupamos em treinar nossos colaboradores sobre a
              importância do tema proteção de dados, a fim de mantê-los
              atualizados quanto às melhores práticas de mercado.
            </p>
          </template>

          <h3>Por quanto tempo guardamos os Dados Pessoais?</h3>

          <p class="pdf-item">
            A retenção de dados pessoais pela {{ nm_rz_social }} respeita as
            seguintes diretrizes:
          </p>

          <ul>
            <li>
              Apenas são mantidos dados pessoais estritamente necessários para o
              cumprimento das finalidades do tratamento. Assim, nos livramos de
              dados pessoais desnecessários ou excessivos.
            </li>
            <li>
              Sem prejuízo, preservamos as informações necessárias para o
              cumprimento de obrigações legais e regulatórias ou o exercício de
              nossos direitos em demandas administrativas, judiciais ou
              arbitrais.
            </li>
          </ul>

          <h3>Quais são os direitos relacionados aos Dados Pessoais?</h3>

          <p class="pdf-item">
            Nos orgulhamos de ser uma empresa que leva a sério os direitos dos
            titulares de dados pessoais. Por isso, garantimos que os direitos
            sobre seus dados pessoais previstos na lei (art. 18, Lei Federal n.
            13.709/2018) possam ser plenamente exercidos, respeitados os limites
            aplicáveis.
          </p>

          <p class="pdf-item">
            <b>Acesso aos dados:</b> qualquer um pode requerer o acesso aos seus
            dados pessoais armazenados por nós, bem como informações sobre
            possível o compartilhamento com terceiros.
          </p>

          <p class="pdf-item">
            <b>Correção:</b> caso os dados pessoais estejam incorretos, poderá
            ser feita uma solicitação de correção ou atualização.
          </p>

          <p class="pdf-item">
            <b>Exclusão</b> dados fornecidos mediante consentimento podem ser
            alvo de exclusão. Contudo, é possível que, mesmo após o requerimento
            de exclusão, alguns dados pessoais permaneçam armazenados, devido a
            obrigações legais ou para a proteção de interesses nossos ou de
            nossos clientes.
          </p>

          <p class="pdf-item">
            <b>Oposição:</b> você pode se opor ao tratamento de seus dados
            pessoais por parte da {{ nm_rz_social }}.
          </p>

          <p class="pdf-item">
            <b>Portabilidade:</b> para que você possa exercer a portabilidade de
            seus dados pessoais, podemos providenciar a você uma cópia de seus
            dados pessoais em formato de leitura comum.
          </p>

          <template v-if="is_nova_clausula">
            <h3 class="text-left">{{ nm_nova_clausula }}</h3>

            <p class="pdf-item" v-html="ds_nova_clausula">
            </p>
          </template>

          <h3>Canais de contato</h3>

          <p class="pdf-item">
            Em caso de dúvidas e solicitações, entre em contato com o nosso
            Encarregado pelo Tratamento de Dados Pessoais, pelo e-mail e
            telefone abaixo:
          </p>

          <p class="pdf-item">
            {{ nm_dpo }}<br />
            {{ cd_email_dpo }}<br />
            {{ cd_telefone_dpo }}
          </p>

          <p class="pdf-item">Será um prazer ajudar!</p>

          <template v-if="settings.settingsExtra.showPowered">
            <powered-by />
          </template>
        </div>
      </section>
    </div>
    <v-style>
      .doc .container { background:
      {{ settings.settingsExtra.backgroundColor }} }
    </v-style>
    <v-style>
      .prose p, .prose li { color: {{ settings.settingsBody.color }}!important;
      font-size: {{ settings.settingsBody.focused_size }}!important;
      font-family: {{ settings.settingsBody.focused_font }}!important }
    </v-style>
    <v-style>
      .prose h1 { color:
      {{ settings.settingsHeading.color }}!important; } .prose h2, .prose h3,
      .prose h3 span { color: {{ settings.settingsHeading.color }}!important;
      font-size: {{ settings.settingsHeading.focused_size }}!important;
      font-family: {{ settings.settingsHeading.focused_font }}!important }
    </v-style>
    <v-style>
      .prose a { color: {{ settings.settingsLink.color }}!important; font-size:
      {{ settings.settingsLink.focused_size }}!important; font-family:
      {{ settings.settingsLink.focused_font }}!important; }
    </v-style>
  </div>
</template>
<script>
import { functions } from '@/utils/functions';

export default {
  name: 'PoliticaPrivacidade',
  created() {},
  methods: {},
  mixins: [functions],
  props: {
    settings: {
      type: Object,
      default() {
        return {
          settingsExtra: {},
          settingsHeading: {},
          settingsLink: {},
          settingsBody: {},
        };
      },
      required: false,
    },
    nm_rz_social: {
      type: String,
      default: '',
      required: false,
    },
    nm_fantasia: {
      type: String,
      default: '',
      required: false,
    },
    cd_cnpj: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_logradouro: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_numero: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_complemento: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_bairro: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_cidade: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_cep: {
      type: String,
      default: '',
      required: false,
    },
    nm_atividade_principal: {
      type: String,
      default: '',
      required: false,
    },
    ds_atividade_principal: {
      type: String,
      default: '',
      required: false,
    },
    is_coletado_dados_pessoais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_nome: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_dt_nascimento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_endereco_comercial: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_email: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_telefone: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_info_pagamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_midias_sociais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_coletado_endereco_residencial: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_ux: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_manu_adm: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_pubi_mark: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_acesso: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_analytics: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_compliance: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_contatar: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_concursos: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_recrutamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_plat_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_conferencia_indentificadores: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_combate_fraudes: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_parceiro: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_compartilhado_autoridade_oficiais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_dados: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_politica_interna: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_plano_resposta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_ssl: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_protegido_treinamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    nm_dpo: {
      type: String,
      default: '',
      required: false,
    },
    cd_email_dpo: {
      type: String,
      default: '',
      required: false,
    },
    cd_telefone_dpo: {
      type: String,
      default: '',
      required: false,
    },
    is_utilizado_cookie: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_necessarios: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_desempenho: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_rede_sociais: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_auth: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_utilizado_cookie_marketing: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_nova_clausula: {
      type: Boolean,
      default: false,
      required: false,
    },
    nm_nova_clausula: {
      type: String,
      default: "",
      required: false,
    },
    ds_nova_clausula: {
      type: String,
      default: "",
      required: false,
    },
  },
  metaInfo() {
    let title;
    let favicon;

    let content;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
      title = 'Compliance Online';
      favicon = './img/icons-co.ico/favicon-16x16.png';

      content = 'Termos de uso e privacidade para adequação na LGPD';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
      title =
        'Gerador de Política de Privacidade 🕵️‍ Gere Políticas de Privacidade GRATUITAS';
      favicon = './img/icons-easy.ico/favicon-16x16.png';

      content =
        'Gere documentos de Termos de Uso e Política de Privacidade para o seu site ou aplicativo sem custos. ';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      link: [
        {
          rel: 'icon',
          href: favicon,
          type: 'image/png',
        },
      ],
      title: title,

      meta: [
        {
          name: 'description',
          content: content,
          icon: favicon,
        },
      ],
    };
  },
};
</script>
